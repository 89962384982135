import React, {useEffect} from "react";
import "../../../assets/scss/views/public/home/Home.scss";
import "../../../assets/scss/components/page/public/Page.scss";
import Carousel from "./components/Carousel";
import FirstBraille from "../../../assets/images/home/first_braille.png";
import SecondBraille from "../../../assets/images/home/second_braille.png";
import Consortium from "./components/Consortium";
import Details from "./components/Details";
import NewsEvents from "./components/NewsEvents";
import {useSelector} from "react-redux";
import Actions from "./components/Actions";
import Section from "../../../ui-component/page/public/section";
import Page from "../../../ui-component/page/public";
import ProjectLinks from "../../../layout/MainLayout/Footer/components/ProjectLinks";
import {useNavigate, useSearchParams} from "react-router-dom";

const Home = () => {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        let link = searchParams.get("redirectAfterLogin");
        if (link) {
            navigate("/login", {
                state: {
                    link: link,
                },
            });
        }
    }, [searchParams]);

    const contents = useSelector((state) => state.main.contents);

    return (
        <Page id={"home"} type={"style1"}>
            <ProjectLinks/>
            <Section fullWidth={true}>
                <Carousel/>
            </Section>
            <Section
                title={contents ? contents["highlights_title"] : ""}
                fullWidth={true}
                centered={true}
            >
                <Actions/>
            </Section>
            <div className={"braille"}>
                <img alt="" className={"first"} src={FirstBraille}/>
            </div>
            <Section marginContent={true} centered={true}>
                <Details/>
            </Section>
            <div className={"braille"}>
                <img alt="" className={"second"} src={SecondBraille}/>
            </div>
            <Section
                title={contents ? contents["home_partners_title"] : ""}
                marginContent={true}
            >
                <Consortium/>
            </Section>
            <Section
                title={contents ? contents["news_events"] : ""}
                marginContent={true}
            >
                <NewsEvents/>
            </Section>
        </Page>
    );
};

export default Home;
