import "../../../../assets/scss/components/page/private/Page.scss";
import React, { useEffect } from "react";
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import { useDispatch, useSelector } from "react-redux";
import {
  InputLabel,
  Link,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { gridSpacing } from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { approve } from "../../../../store/actions/news_events/events";
import {
  getData as getImageData,
  getImagePath,
} from "../../../../store/actions/general/image";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Image from "mui-image";
import {
  getData as getFileData,
  getFilePath,
} from "../../../../store/actions/general/file";
import { getSimpleDate } from "../../../../util/date_util";

const HtmlToReactParser = require("html-to-react").Parser;

const ViewEventDetail = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.login.userType);
  const userName = useSelector((state) => state.login.username);
  const loc = useLocation();
  const event = loc?.state?.event;

  const imageData = useSelector((state) => state.image.imageData);
  const fileData = useSelector((state) => state.file.fileData);

  useEffect(() => {
    if (event) {
      if (event.image) {
        getImageData(dispatch, event.image);
      }

      if (event.agenda) {
        getFileData(dispatch, event.agenda);
      }

      if (event.gallery) {
        for (let item of event.gallery) {
          getImageData(dispatch, item);
        }
      }

      if (event.presentations) {
        for (let item of event.presentations) {
          getFileData(dispatch, item);
        }
      }
    }
  }, [event, dispatch]);

  const htmlToReactParser = new HtmlToReactParser();

  const handleApprove = (status) => {
    approve(dispatch, {
      data: event,
      status: status,
    });
    navigate("/website-editor/home");
  };

  return (
    <Page title={"Event Detail"} id={"viewEvent"} type={"style1"}>
      <Section centered={true}>
        <MainCard title="Event" style={{ width: "100%" }}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="medium" aria-label="a dense table">
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>Name</InputLabel>
                        {event.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>
                          Start Date
                        </InputLabel>
                        {getSimpleDate(event.start_date)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>
                          End Date
                        </InputLabel>
                        {getSimpleDate(event.end_date)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>
                          Location
                        </InputLabel>
                        {event.location}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>
                          Category
                        </InputLabel>
                        {event.category}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>
                          Organizer
                        </InputLabel>
                        {event.organizer}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>Info</InputLabel>
                        {htmlToReactParser.parse(event?.info)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>
                          Venues
                        </InputLabel>
                        {htmlToReactParser.parse(event?.venues)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>Image</InputLabel>
                        {imageData[event?.image] ? (
                          <Image
                            src={getImagePath(imageData[event?.image])}
                            style={{ width: "10em" }}
                          />
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>
                          Agenda
                        </InputLabel>
                        {fileData[event?.agenda] ? (
                          <Link
                            href={getFilePath(fileData[event?.agenda])}
                            color="inherit"
                            target={"_blank"}
                          >
                            {fileData[event?.agenda].name}
                          </Link>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>
                          Presentation Files
                        </InputLabel>
                        {event?.presentations?.map((presentation, i) => {
                          if (!presentation) {
                            return <></>;
                          }

                          let data = fileData[presentation];
                          if (!data) {
                            return <></>;
                          }
                          return (
                            <Link
                              href={getFilePath(data)}
                              color="inherit"
                              target={"_blank"}
                              key={"presentation_" + i}
                            >
                              {data.name}
                              <br />
                            </Link>
                          );
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>
                          Gallery
                        </InputLabel>
                        {event?.gallery?.map((gallery, i) => {
                          if (!gallery) {
                            return <></>;
                          }

                          let data = imageData[gallery];
                          if (!data) {
                            return <></>;
                          }

                          return (
                            <Image
                              src={getImagePath(data)}
                              style={{ width: "10em" }}
                              key={"gallery_" + i}
                            />
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Stack direction="row" spacing={2} style={{ marginTop: "1rem" }}>
                {userType === "Web Site Editor" && event.owner == userName ? (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      margin="normal"
                      onClick={() => handleApprove("APPROVE")}
                    >
                      Approve
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      margin="normal"
                      onClick={() => handleApprove("REJECT")}
                    >
                      Reject
                    </Button>
                  </>
                ) : (
                  <></>
                )}
                {userType === "Web Site Editor" && event.owner === userName ? (
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={() => handleApprove("REJECT")}
                  >
                    Undo
                  </Button>
                ) : (
                  <></>
                )}
              </Stack>
            </Grid>
          </Grid>
        </MainCard>
      </Section>
    </Page>
  );
};

export default ViewEventDetail;
