import "../../../../assets/scss/components/page/private/Page.scss";
import {useEffect, useState} from 'react';
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import {useDispatch, useSelector} from "react-redux";
import {
    InputLabel,
    Stack,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {gridSpacing} from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {save} from "../../../../store/actions/news_events/events";
import {getData as getImageData} from "../../../../store/actions/general/image";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import {raiseError} from "../../../../store/actions/general/error";
import {getData as getFileData} from "../../../../store/actions/general/file";
import HTMLEditor from "../../../../ui-component/Editor";
import {reset as resetEditor} from "../../../../store/actions/general/editor";
import {reset as resetFileUploader} from "../../../../store/actions/general/file_uploader";
import FileUploader from "../../../../ui-component/FileUploader";
import {SAVE} from "../../../../store/actions/news_events/news";

const AddEvent = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const loc = useLocation();
    const username = useSelector((state) => state.login.userId);
    const event = loc?.state?.event;
    const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);
    const newFiles = useSelector((state) => state.fileUploader.newFiles);
    const oldFiles = useSelector((state) => state.fileUploader.oldFiles);

    const [name, setName] = useState(event ? event.name : '');
    const [location, setLocation] = useState(event ? event.location : '');
    const [category, setCategory] = useState(event ? event.category : '');
    const [organizer, setOrganizer] = useState(event ? event.organizer : '');
    const [startDate, setStartDate] = useState(event ? dayjs(event.start_date, "YYYY-MM-DD HH:mm:ss.SSS") : dayjs());
    const [endDate, setEndDate] = useState(event ? dayjs(event.end_date, "YYYY-MM-DD HH:mm:ss.SSS") : dayjs());
    const [image, setImage] = useState(event ? [event?.image] : []);
    const [agenda, setAgenda] = useState(event ? [event?.agenda] : []);
    const [presentations, setPresentations] = useState(event ? event?.presentations : []);
    const [gallery, setGallery] = useState(event ? event?.gallery : []);
    const loading = useSelector((state) => state.loading);

    useEffect(() => {
        switch (loading?.completedOperation) {
            case SAVE.method: {
                resetEditor(dispatch);
                resetFileUploader(dispatch);
                navigate("/website-editor/home");
                break;
            }
        }
    }, [loading?.completedOperation, dispatch, navigate]);

    useEffect(() => {
        if (event) {
            setName(event.name);
            setLocation(event.location);
            setCategory(event.category);
            setOrganizer(event.organizer);
            setStartDate(dayjs(event.start_date, "YYYY-MM-DD HH:mm:ss.SSS"));
            setEndDate(dayjs(event.end_date, "YYYY-MM-DD HH:mm:ss.SSS"));
            setImage([event.image]);
            setAgenda([event.agenda]);
            setPresentations(event.presentations);
            setGallery(event.gallery);

            if (event.image) {
                getImageData(dispatch, event.image);
            }

            if (event.agenda) {
                getFileData(dispatch, event.agenda);
            }

            if (event.gallery) {
                for (let item of event.gallery) {
                    getImageData(dispatch, item);
                }
            }

            if (event.presentations) {
                for (let item of event.presentations) {
                    getFileData(dispatch, item);
                }
            }
        }
    }, [event, dispatch]);

    let validate = () => {
        if (name.length === 0) {
            raiseError(dispatch, {
                message: "Empty name!",
            });

            return false;
        }

        if (location.length === 0) {
            raiseError(dispatch, {
                message: "Empty location!",
            });

            return false;
        }

        if (category.length === 0) {
            raiseError(dispatch, {
                message: "Empty category!",
            });

            return false;
        }

        if (organizer.length === 0) {
            raiseError(dispatch, {
                message: "Empty organizer!",
            });

            return false;
        }

        if (
            !Object.keys(editorHTMLs).includes("info") &&
            editorHTMLs["info"].length === 0
        ) {
            raiseError(dispatch, {
                message: "Empty info!",
            });
            return false;
        }

        if (
            !Object.keys(newFiles).includes("image") || Object.values(newFiles["image"]).length === 0
        ) {
            raiseError(dispatch, {
                message: "Empty image!",
            });
            return false;
        }

        return true;
    }

    const add = () => {
        let valid = validate();
        if (!valid) {
            return
        }

        save(dispatch, {
            "image": Object.values(newFiles["image"]),
            "agenda": Object.values(newFiles["agenda"]),
            "presentations": Object.values(newFiles["presentations"]),
            "gallery": Object.values(newFiles["gallery"])
        }, {
            name: name,
            location: location,
            category: category,
            organizer: organizer,
            info: editorHTMLs["info"],
            start_date: startDate,
            end_date: endDate,
            venues: editorHTMLs["venues"],
            owner: username,
            status: "ADD",
            images: [],
            agendas: [],
            presentations: [],
            gallery: []
        });

        resetEditor(dispatch);
        resetFileUploader(dispatch);
        navigate("/website-editor/home");
    };

    const edit = () => {
        let valid = validate();
        if (!valid) {
            return
        }

        save(dispatch, {
            "image": Object.values(newFiles["image"]),
            "agenda": Object.values(newFiles["agenda"]),
            "presentations": Object.values(newFiles["presentations"]),
            "gallery": Object.values(newFiles["gallery"])
        }, {
            id: event.id,
            event_id: event.id,
            name: name,
            location: location,
            category: category,
            organizer: organizer,
            info: editorHTMLs["info"],
            start_date: startDate,
            end_date: endDate,
            venues: editorHTMLs["venues"],
            owner: username,
            images: Object.keys(oldFiles["image"]),
            agendas: Object.keys(oldFiles["agenda"]),
            presentations: Object.keys(oldFiles["presentations"]),
            gallery: Object.keys(oldFiles["gallery"]),
            status: "UPDATE"
        });

        resetEditor(dispatch);
        resetFileUploader(dispatch);
        navigate("/website-editor/home");
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const handleChangeLocation = (event) => {
        setLocation(event.target.value);
    };

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    const handleChangeOrganizer = (event) => {
        setOrganizer(event.target.value);
    };

    const handleChangeStartDate = (event) => {
        setStartDate(event);
    };

    const handleChangeEndDate = (event) => {
        setEndDate(event);
    };

    return (
        <Page title={"Add Event"} id={"addEvent"} type={"style1"}>
            <Section centered={true}>
                <MainCard title="Add/Edit Event" style={{width: "100%"}}>
                    <Grid container spacing={gridSpacing} columns={{xs: 12}}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="medium" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <TextField
                                                    fullWidth
                                                    label="Name"
                                                    id="name"
                                                    value={name}
                                                    onChange={handleChangeName}
                                                    error={name.length > 0 && name.length < 3}
                                                    helperText={
                                                        name && (name.length < 3) ? 'Enter a valid name.' : ''
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker value={startDate} label={"Start Date"}
                                                                onChange={handleChangeStartDate}
                                                                format={"DD/MM/YYYY"}/>
                                                </LocalizationProvider>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker value={endDate} label={"End Date"}
                                                                onChange={handleChangeEndDate}
                                                                format={"DD/MM/YYYY"}/>
                                                </LocalizationProvider>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <TextField
                                                    fullWidth
                                                    label="Location"
                                                    id="location"
                                                    value={location}
                                                    onChange={handleChangeLocation}
                                                    error={location.length > 0 && location.length < 3}
                                                    helperText={
                                                        location && (location.length < 3) ? 'Enter a valid location.' : ''
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <TextField
                                                    fullWidth
                                                    label="Category"
                                                    id="category"
                                                    value={category}
                                                    onChange={handleChangeCategory}
                                                    error={category.length > 0 && category.length < 3}
                                                    helperText={
                                                        category && (category.length < 3) ? 'Enter a valid category.' : ''
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <TextField
                                                    fullWidth
                                                    label="Organizer"
                                                    id="organizer"
                                                    value={organizer}
                                                    onChange={handleChangeOrganizer}
                                                    error={organizer.length > 0 && organizer.length < 3}
                                                    helperText={
                                                        organizer && (organizer.length < 3) ? 'Enter a valid organizer.' : ''
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel>Info</InputLabel>
                                                <HTMLEditor id={"info"} html={event?.info}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel>Venues</InputLabel>
                                                <HTMLEditor id={"venues"} html={event?.venues}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel>Image</InputLabel>
                                                <FileUploader id={"image"} files={image}
                                                              isImage={true}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel>Agenda</InputLabel>
                                                <FileUploader id={"agenda"} files={agenda}
                                                              isFile={true} maxFileSize={10}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel>Presentation Files</InputLabel>
                                                <FileUploader id={"presentations"} files={presentations}
                                                              isFile={true} maxFileAllowed={10} maxFileSize={10}/> </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel>Gallery</InputLabel>
                                                <FileUploader id={"gallery"} files={gallery}
                                                              isImage={true} maxFileAllowed={30} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Stack direction="row" spacing={2} style={{marginTop: '1rem'}}>
                                {event ? (
                                    <Button type="submit" variant="contained" margin="normal" onClick={edit}>
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        margin="normal"
                                        onClick={add}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Section>
        </Page>
    );
};

export default AddEvent;
