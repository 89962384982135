import React, { useEffect } from "react";
import "../../../../assets/scss/views/private/Dashboard.scss";
import "../../../../assets/scss/components/page/private/Page.scss";
import { useDispatch, useSelector } from "react-redux";
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import { useNavigate } from "react-router-dom";
import Carousel from "../../../public/home/components/Carousel";
import Details from "../../../public/home/components/Details";
import SecondBraille from "../../../../assets/images/home/second_braille.png";
import Consortium from "../../../public/home/components/Consortium";
import NewsEvents from "../../../public/home/components/NewsEvents";
import TeachingPlatformBanner from "./TeachingPlatformBanner";

const Dashboard = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const contents = useSelector((state) => state.main.contents);

  const userType = useSelector((state) => state.login.userType);
  const userName = useSelector((state) => state.login.username);

  useEffect(() => {
    if (!(userType && userName)) {
      navigate("/login");
    }
  }, [dispatch]);

  if (userName && userType) {
    return (
      <Page id={"home"} type={"style1"}>
        <Section centered={true}>
          <TeachingPlatformBanner />
        </Section>
        <Section fullWidth={true}>
          <Carousel />
        </Section>
        <Section marginContent={true} centered={true}>
          <Details hideDetails={true} />
        </Section>
        <div className={"braille"}>
          <img alt="" className={"second"} src={SecondBraille} />
        </div>
        <Section
          title={contents ? contents["home_partners_title"] : ""}
          marginContent={true}
        >
          <Consortium />
        </Section>
        <Section
          title={contents ? contents["news_events"] : ""}
          marginContent={true}
        >
          <NewsEvents />
        </Section>
      </Page>
    );
  }
};

export default Dashboard;
