import React, {useEffect, useState} from 'react';
import "../../../../../assets/scss/components/page/public/Page.scss";
import "../../../../../assets/scss/Common.scss";
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../../../ui-component/page/public/section";
import Page from "../../../../../ui-component/page/public";
import {get as getMain} from "../../../../../store/actions/idm/guidelines/idm_guidelines";
import {getAll as getFiles, get as getFile} from "../../../../../store/actions/idm/guidelines/idm_guidelines_files";
import {Parser as HtmlToReactParser} from "html-to-react";
import {getData as getFileData, getFilePath} from "../../../../../store/actions/general/file";

const IDMGuidelines = () => {
    const dispatch = useDispatch();
    const contents = useSelector((state) => state.main.contents);

    const main = useSelector((state) => state.idmGuidelines.data);
    const list = useSelector((state) => state.idmGuidelinesFiles.list);
    const listFull = useSelector((state) => state.idmGuidelinesFiles.listFull);
    const htmlToReactParser = new HtmlToReactParser();
    const fileData = useSelector((state) => state.file.fileData);
    const [downloadFiles, setDownloadFiles] = useState([]);

    useEffect(() => {
        getMain(dispatch);
        getFiles(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (list) {
            for (let data of list) {
                getFile(dispatch, data.id);
            }
        }
    }, [list, dispatch]);

    useEffect(() => {
        if (list) {
            for (let item of list) {
                getFileData(dispatch, item.file);
            }
        }
    }, [list, dispatch]);

    useEffect(() => {
        if (list) {
            let results = []
            for (let item of list) {
                if (fileData[item.file]) {
                    results.push(fileData[item.file]);
                }
            }
            setDownloadFiles(results);
        }
    }, [fileData, list]);

    if (!list || !main) {
        return <></>;
    }

    return (
        <Page id={"guidelines"} type={"style1"} title={contents ? contents["guidelines"] : ""}>
            <Section>
                <div className={"left-side"}>
                    <p className={"title"}></p>
                </div>
                <div className={"right-side"}>
                    <div className={"content"}>{htmlToReactParser.parse(main.info)}</div>
                </div>
            </Section>
            <Section>
                <div className={"left-side"}>
                    <p className={"title"}>Files</p>
                </div>
                <div className={"right-side"}>
                    <div className={"files"}>
                        {list.map((file, i) => {
                            if (!file) {
                                return <></>
                            }

                            if (!downloadFiles[i]) {
                                return <></>
                            }

                            return <div className={"file"} key={i} style={{marginBottom: "2em"}}>
                                <a className={"title"}
                                   href={getFilePath(downloadFiles[i])}
                                   target={"_blank"}>{file.name}</a>
                                <div
                                    className={"subTitle"}>({file.type.replace('application/', '')}, {downloadFiles[i].size} KB)
                                </div>
                                <div
                                    className={"detail"}>{htmlToReactParser.parse(file?.info)}</div>
                                <a className={"download"}
                                   href={getFilePath(downloadFiles[i])}
                                   target={"_blank"}>{contents ? contents["download_click_here_to_download"] : ""}</a>
                            </div>
                        })
                        }
                    </div>
                </div>
            </Section>
        </Page>
    );
};

export default IDMGuidelines;
