import React, {useEffect} from 'react';
import "../../../assets/scss/components/page/public/Page.scss";
import "../../../assets/scss/views/public/NewsEvents.scss";
import Page from "../../../ui-component/page/public";
import {useDispatch, useSelector} from "react-redux";
import {getAll as getAllEvents} from "../../../store/actions/news_events/events";
import {useNavigate} from "react-router-dom";
import Section from "../../../ui-component/page/public/section";
import {getAll as getAllNews} from "../../../store/actions/news_events/news";
import {getData, getImagePath} from "../../../store/actions/general/image";
import {getSimpleDate} from "../../../util/date_util";

function Event({event}) {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const imageData = useSelector((state) => state.image.imageData);

    useEffect(() => {
        if (event.image) {
            getData(dispatch, event.image);
        }
    }, [event?.image]);

    const handleKeyUp = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            navigate("/events/detail", {
                state: {
                    event: event
                }
            });
        }
    };

    const handleClick = () => {
        navigate("/events/detail", {
            state: {
                event: event
            }
        });
    };

    return (
        <div className={"event clickable"} role={"button"} tabIndex={0} onClick={handleClick} onKeyUp={handleKeyUp}>
            <div className={"image"}>
                {imageData[event.image] ?
                    <img alt={imageData[event.image].name} src={getImagePath(imageData[event.image])}/> : <></>}
            </div>
            <div className={"info"}>
                <p className={"date"}>{getSimpleDate(event.start_date)} - {getSimpleDate(event.end_date)}</p>
                <p className={"name"}>{event.name}</p>
            </div>
        </div>
    );
}

function News({news}) {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const imageData = useSelector((state) => state.image.imageData);

    useEffect(() => {
        if (news.image) {
            getData(dispatch, news.image);
        }
    }, [news?.image]);

    const handleKeyUp = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            navigate("/news/detail", {
                state: {
                    news: news
                }
            });
        }
    };

    const handleClick = () => {
        navigate("/news/detail", {
            state: {
                news: news
            }
        });
    };

    return (
        <div className={"event clickable"} role={"button"} tabIndex={0} onClick={handleClick} onKeyUp={handleKeyUp}>
            <div className={"image"}>
                {imageData[news.image] ?
                    <img alt={imageData[news.image].name} src={getImagePath(imageData[news.image])}/> : <></>}
            </div>
            <div className={"info"}>
                <p className={"date"}>{getSimpleDate(news.date)}</p>
                <p className={"name"}>{news.name}</p>
            </div>
        </div>
    );
}

const NewsEvents = () => {
    const dispatch = useDispatch();
    const contents = useSelector((state) => state.main.contents);
    const selectedLanguage = useSelector((state) => state.main.selectedLanguage);

    useEffect(() => {
        getAllEvents(dispatch);
        getAllNews(dispatch);
    }, []);

    const events = useSelector((state) => state.event.events);
    const news = useSelector((state) => state.news.news);

    if (!events) {
        return <></>;
    }

    if (!news) {
        return <></>;
    }

    return (
        <Page id={"events"} type={"style1"} title={contents ? contents["news_events_title"] : ""}>
            {
                events.map((item, i) => {
                    return (
                        <Section key={i}>
                            <Event event={item} key={i}></Event>;
                        </Section>
                    );
                })
            }
            {
                news.map((item, i) => {
                    return (
                        <Section key={i}>
                            <News news={item} key={i}></News>;
                        </Section>
                    );
                })
            }
        </Page>
    );
};

export default NewsEvents;