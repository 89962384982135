import {useEffect, useState} from 'react';
import "../../../assets/scss/components/page/public/Page.scss";
import "../../../assets/scss/views/public/Download.scss";
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../ui-component/page/public/section";
import Page from "../../../ui-component/page/public";
import {getAll} from "../../../store/actions/download";
import {Parser as HtmlToReactParser} from "html-to-react";
import {getData as getFileData, getFilePath} from "../../../store/actions/general/file";

const Download = () => {
    const dispatch = useDispatch();
    const contents = useSelector((state) => state.main.contents);

    useEffect(() => {
        getAll(dispatch);
    }, [dispatch]);

    const downloads = useSelector((state) => state.downloads.downloads);
    const fileData = useSelector((state) => state.file.fileData);
    const htmlToReactParser = new HtmlToReactParser();
    const [types, setTypes] = useState([]);
    const [downloadFiles, setDownloadFiles] = useState({});

    useEffect(() => {
        let _types = {}
        for (let download of downloads) {
            _types[download.type] = {}
        }
        setTypes(Object.keys(_types));
    }, [downloads]);

    useEffect(() => {
        if (downloads) {
            for (let item of downloads) {
                if(item.file) {
                    getFileData(dispatch, item.file);
                }
            }
        }
    }, [downloads, dispatch]);

    useEffect(() => {
        if (downloads) {
            let results = {}
            for (let item of downloads) {
                if (fileData[item.file]) {
                    results[item.id] = fileData[item.file];
                }
            }
            setDownloadFiles(results);
        }
    }, [fileData, downloads]);

    if (!downloads) {
        return <></>;
    }

    return (
        <Page id={"download"} type={"style1"}>
            {
                types.map((type, j) => {
                    return <>
                        <Section key={"1" + j}>
                            <div className={"left-side"}>
                                <div className={"title"}>
                                    <p>{type}</p>
                                </div>
                            </div>
                            <div className={"right-side"}>
                                <div className={"files"}>
                                    {downloads.map((download, i) => {
                                        if (!download) {
                                            return <></>
                                        }

                                        if (download.type !== type) {
                                            return <></>
                                        }

                                        if (!downloadFiles[download.id]) {
                                            return <div className={"file"} key={i}>
                                                <a className={"title"}
                                                   href={"/downloads"}
                                                   target={"_self"}>{download.name}</a>
                                                <div
                                                    className={"subTitle"}>{htmlToReactParser.parse(download?.info)}
                                                </div>
                                            </div>
                                        }

                                        return <div className={"file"} key={i}>
                                            <a className={"title"}
                                               href={getFilePath(downloadFiles[download.id])}
                                               target={"_blank"}>{download.name}</a>
                                            <div
                                                className={"subTitle"}>({download.type.replace('application/', '')}, {downloadFiles[download.id].size} KB)
                                            </div>
                                            <div
                                                className={"detail"}>{htmlToReactParser.parse(download?.info)}</div>
                                            <a className={"download"}
                                               href={getFilePath(downloadFiles[download.id])}
                                               target={"_blank"}>{contents ? contents["download_click_here_to_download"] : ""}</a>
                                        </div>
                                    })
                                    }
                                </div>
                            </div>
                        </Section>
                        <Section key={"2" + j}>
                            <div className={"divider"}></div>
                        </Section>
                    </>
                })
            }
        </Page>
    );
};

export default Download;
