import React, { useEffect, useState } from "react";
import "../../../../../assets/scss/components/page/public/Page.scss";
import "../../../../../assets/scss/Common.scss";
import { useDispatch, useSelector } from "react-redux";
import Section from "../../../../../ui-component/page/public/section";
import Page from "../../../../../ui-component/page/public";
import { get as getMain } from "../../../../../store/actions/three_d_models/gallery/three_d_models_gallery";
import {
  get as getImage,
  getAll as getImages,
} from "../../../../../store/actions/three_d_models/gallery/three_d_models_gallery_files";
import { Parser as HtmlToReactParser } from "html-to-react";
import {
  getData as getImageData,
  getImagePath,
} from "../../../../../store/actions/general/image";
import Image from "mui-image";

const ThreeDModelsGallery = () => {
  const dispatch = useDispatch();
  const contents = useSelector((state) => state.main.contents);

  const main = useSelector((state) => state.threeDModelsGallery.data);
  const list = useSelector((state) => state.threeDModelsGalleryFiles.list);
  const listFull = useSelector(
    (state) => state.threeDModelsGalleryFiles.listFull
  );
  const htmlToReactParser = new HtmlToReactParser();
  const imageData = useSelector((state) => state.image.imageData);
  const [downloadFiles, setDownloadFiles] = useState([]);

  useEffect(() => {
    getMain(dispatch);
    getImages(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (list) {
      for (let data of list) {
        getImage(dispatch, data.id);
      }
    }
  }, [list, dispatch]);

  useEffect(() => {
    if (list) {
      for (let item of list) {
        getImageData(dispatch, item.image);
      }
    }
  }, [list, dispatch]);

  useEffect(() => {
    if (list) {
      let results = [];
      for (let item of list) {
        if (imageData[item.image]) {
          results.push(imageData[item.image]);
        }
      }
      setDownloadFiles(results);
    }
  }, [imageData, list]);

  if (!list || !main) {
    return <></>;
  }

  return (
    <Page
      id={"three3ModelsGallery"}
      type={"style1"}
      title={contents ? contents["gallery"] : ""}
    >
      <Section>
        <div className={"left-side"}>
          <p className={"title"}></p>
        </div>
        <div className={"right-side"}>
          <div className={"content"}>{htmlToReactParser.parse(main.info)}</div>
        </div>
      </Section>
      <Section>
        <div className={"left-side"}>
          <p className={"title"}>Images</p>
        </div>
        <div className={"right-side"}>
          <div className={"files"}>
            {list.map((image, i) => {
              if (!image) {
                return <></>;
              }

              if (!downloadFiles[i]) {
                return <></>;
              }

              return (
                <div
                  className={"image"}
                  key={i}
                  style={{ marginBottom: "2em" }}
                >
                  <a
                    className={"title"}
                    href={getImagePath(downloadFiles[i])}
                    target={"_blank"}
                  >
                    {image.name}
                  </a>
                  <div className={"detail"}>
                    {htmlToReactParser.parse(image?.info)}
                  </div>
                  <Image
                    src={getImagePath(downloadFiles[i])}
                    style={{ width: "10em" }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Section>
    </Page>
  );
};

export default ThreeDModelsGallery;
