import "../assets/scss/components/Carousel.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as PropTypes from "prop-types";

function Item({ image, text }) {
  return (
    <div>
      <link rel="preload" as="image" href={image} />
      <img src={image} alt={text} />
      {text ? (
        <div className="carousel-component">
          <div className="item">
            <p className="carousel-item">{text}</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

Item.propTypes = {
  image: PropTypes.any,
  text: PropTypes.any,
};

const Gallery = ({ items, timeInterval = 12000, dynamicHeight = true }) => {
  return (
    <div className={"carousel"}>
      {items ? (
        <Carousel
          autoPlay={true}
          resetAutoPlay={true}
          dynamicHeight={dynamicHeight}
          infiniteLoop={true}
          interval={timeInterval}
          stopOnHover={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          selectedItem={items.length - 1}
        >
          {items.map((item, i) => {
            return <Item image={item.image} text={item.text} key={i}></Item>;
          })}
        </Carousel>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Gallery;
